import 'velocity-animate';
import * as d3 from 'd3';





jQuery(document).ready(function ($) {

$(document).ready( function() {
 bgPos();
    $(''+indicators[contentIndex]+'').stop(true).velocity({opacity:1,scale:1},{duration:200},{ queue: false });




  $(".descDiv,.small-buttons").velocity({translateX:[ 40, 0 ]}, {duration:400});
  $(".desc, .more-button, .contact-button").velocity({opacity:1},{visibility:"visible", duration: 400,complete: function() {
      $("#about-bg").velocity({opacity: 1},{duration:200},"easeInQuint");
    }});
 



});

const mapNumber = (number, in_min, in_max, out_min, out_max) => {
  return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

    $(window).resize(function(){
  position_menuindicator(menu.find("li.activea")); 
 
bgPos();
  });


var aboutmeCLicked=true;
var contentIndex=0;
var oldContentIndex=0;
var navButtons= ["#aboutme","#design","#podcast","#vlog","#music"];
var descs= ["#aboutme_desc","#design_desc","#podcast_desc","#vlog_desc","#music_desc"];
var bgColors=["#ffffff","#ffffff", "#ffffff", "#ffffff","#ffffff"]
var polyColor= ["#000000","#ced3db","#2abdf1","#ffde00","#e23b3b"];
var baseColor= ["#a3a3a3","#5c5c5c","#2880b2","#f0af25","#a52300"];
var wordsArray = ["creative", "digital designer", "podcaster", "storyteller", "tinkerer"];
var wordColor= ["#5c5c5c","#5c5c5c","#2abdf1","#f0af25","#e23b3b"];

var moreColor= ["#5c5c5c","#5c5c5c","#2abdf1","#f0af25","#e23b3b"];
var moretxtColor= ["#ffffff","#ffffff","#ffffff","#ffffff","#ffffff"];



var moreButtons = ["blog", "work", "podcasts", "videos", "music"];
var contactButtons = ["contact", "contact", "contact", "contact", "contact"];

var moreLinks = ["https://blog.mesutkaya.com", "https://uifux.com", "https://mkpods.com", "https://youtube.com/mesutkaya", "https://soundcloud.com/iammesutkaya"];
var moreIcons = [".blog-ico", ".work-ico", ".play-ico", ".play-ico", ".play-ico"];


var indicators= ["#aboutme-indicator","#design-indicator","#podcast-indicator","#vlog-indicator","#music-indicator"];
var descTexts =[

'Currently living in Karlsruhe, Germany. I created this page, so you could get an overview of the things I do. I’m always looking for opportunities, to do more of what I love, so don’t hesitate to contact me if you want to work with me.',

'UI/UX and motion design are my jam. I’m a HfG Schwaebisch Gmuend almuni with a BA in interaction design and a MA in communication and planning design, with over seven years of wok experience.',

'I use podcasts to declutter my brain. So mostly loose thoughts, in a loose format. I’ve created a podcast network for all the podcasts I’m involved in. Most of my podcasts are in German.',

'I probably enjoy creating videos the most. Figuring out how to tell a story and getting there frame by frame, is time consuming, but too much fun to pass on. I mainly upload to YouTube.', 

'Most of my music is based on the necessity of having music in my other endeavours. My approach to music is similar to doodling - making sense of a bunch of seemingly incoherent lines.'
];









    $(".socialIcon")
    .mouseenter(function () {
      $(this).stop(true).velocity({opacity:1,scale:1.2},{duration:200},{ queue: false });
  })

    .mouseleave(function () {
      $(this).stop(true).velocity({opacity:0.5,scale:1},{duration:200},{ queue: false });

      });

    $(".colorLinks")
    .mouseenter(function () {
      $(this).stop(true).velocity({opacity:1},{duration:200},{ queue: false });
  })

    .mouseleave(function () {
      $(this).stop(true).velocity({opacity:0.5},{duration:200},{ queue: false });
      });




function bgPos(){

var bgPhotoWidth = $(".bgPhoto").width(),
    bgPhotoHeight = $(".bgPhoto").height(),
    bgWidth = 1920,
    bgHeight = 1080;
  if($( window ).height()>800){
var moveBG = mapNumber(bgPhotoWidth,375, 1920, -300, 1600);
}else{
var moveBG = mapNumber(bgPhotoWidth,375, 1920, -40, 1800);

}


$(".bgPhoto").css({
  //"z-index":"-800",
  "background-position": (0-bgPhotoWidth+moveBG)+ "px bottom",
  "background-size": ("auto "+bgPhotoHeight+"px" )

});
};


var isAnimating = false;

  $(document).keydown(function(e){

    if (e.keyCode == 39 && isAnimating==false ){
          oldContentIndex=contentIndex;

      if(contentIndex<4){
        contentIndex++;
    }else if (contentIndex==4){
        contentIndex=0;
    }
          contentSwitch(); 

}else{
  return;
}



});

    $(document).keydown(function(e){

    if (e.keyCode == 37 && isAnimating==false ){
          oldContentIndex=contentIndex;

      if(contentIndex>0){
        contentIndex--;
    }else if (contentIndex==0){
        contentIndex=4;
    }
          contentSwitch(); 

}else{
  return;
}



});





  var menu = $(".menu");
  var menuindicator = $('<span class="menuindicator"></span>');

  function position_menuindicator(ele){
      var left = ele.offset().left ;
      var width = ele.width();

      menuindicator.stop().animate({
        left: left,
        width: width
      });
  }


$(function(){

  menu.append(menuindicator);
  position_menuindicator(menu.find("li.activea"));  
  setTimeout(function(){menuindicator.css("opacity", 1);}, 500);
  menu.find("li").click(function(){
    position_menuindicator($(this));
    menu.find("li").removeClass("activea");
        $(this).addClass("activea");
  });


});






    $(".indicator")
    .mouseenter(function () {
      $(this).stop(true).velocity({scale:1.5},{duration:200},{ queue: false });
  })

    .mouseleave(function () {
      $(this).stop(true).velocity({scale:1},{duration:200},{ queue: false });

  
      });






  //Change State of "Burger"
  var circleMenu = document.getElementById("circleMenu");
  $("#offCanvas").on("closed.zf.offcanvas", function () {
    circleMenu.classList.toggle("is-active");
});
  $("#offCanvas").on("opened.zf.offcanvas", function () {
    circleMenu.classList.toggle("is-active");
});


  var circleSocial = document.getElementById("circleSocial");
  $("#offCanvasSocial").on("closed.zf.offcanvas", function () {
    circleSocial.classList.toggle("is-active");
});
  $("#offCanvasSocial").on("opened.zf.offcanvas", function () {
    circleSocial.classList.toggle("is-active");
});





    $(".navButton")
    .mouseenter(function () {
      $(this).stop(true).velocity({opacity:0.5,scale:1.05},{duration:200},{ queue: false });
  })

    .mouseleave(function () {
      $(this).stop(true).velocity({opacity:1,scale:1},{duration:200},{ queue: false });
      });


 $(".rArw").click(function(){

  oldContentIndex=contentIndex;
      if(isAnimating==false){

           if(contentIndex<4){
        contentIndex++;
    }else if (contentIndex==4){
        contentIndex=0;
    }
    contentSwitch(); 
}
  });

  $(".lArw").click(function(){
    oldContentIndex=contentIndex;
    if(isAnimating==false){
    if(contentIndex>0){
        contentIndex--;
    }else if (contentIndex==0){
        contentIndex=4;
    }
    contentSwitch(); 
}
  });















  function indicatorUpdate(){

    $(''+indicators[oldContentIndex]+'').stop(true).velocity({opacity:0.2,scale:1},{duration:200},{ queue: false });
    $(''+indicators[contentIndex]+'').stop(true).velocity({opacity:1,scale:1},{duration:200},{ queue: false });
};


























function animDescCon(){


    isAnimating = true;
    $(".descDiv, .small-buttons").velocity({translateX:[ 80, 40 ]}, {duration:400});
    $(".desc").velocity({opacity:0, visibility:"hidden", duration:600, complete: function() { 
        $(".descDiv, .small-buttons").velocity({translateX:[ 40, 0 ]}, {duration:400});
        $(".desc").velocity({opacity:1, visibility:"visible", duration:400});
    }});



    $(".more-button, .contact-button").velocity({opacity:0},{duration: 400,complete: function() { 
      $(".more-button-txt").text(moreButtons[contentIndex]);
      $(".contact-button-txt").text(contactButtons[contentIndex]);

      $(".more-button").attr("href", moreLinks[contentIndex]);
      $(moreIcons[oldContentIndex]).velocity({opacity:0},{duration:0});
      $(moreIcons[contentIndex]).velocity({opacity:1},{duration:0} );
      $(".more-button").velocity({backgroundColor: moreColor[contentIndex], color: moretxtColor[contentIndex]},{duration: 0});
      $(".more-button, .contact-button").velocity({opacity:1},{duration: 400});

       }});


    
setTimeout(function() {

        isAnimating = false;
    }, 800);


}

  







var wordlength=0;
var isTyping=false;



function typeWriter() {
      
        if (wordlength < wordsArray[contentIndex].length) {
        document.getElementById("word").innerHTML += wordsArray[contentIndex].charAt(wordlength);
        wordlength++;
        isTyping=true;
        setTimeout(typeWriter,80);
          }else{
            wordlength=0;
            isTyping=false;
          }
      }



  function changeText() {

    $("#word").velocity({opacity:0},{duration:200,complete: function() {

      wordlength=0;
      document.getElementById("word").innerHTML = "";

      $("#word").velocity({"color": wordColor[contentIndex]});
      $("#word").velocity({opacity:1},{duration: 0,complete: function() {
      
      if (isTyping==false){
        typeWriter();
      }
      
      }});

      }});

  




    $("#descText").velocity("fadeOut",{duration: 400,complete: function() { 
        $("#descText").text(descTexts[contentIndex]);
        $("#descText").velocity("fadeIn",{duration: 400});
      }});


};





(function() {
  if (!localStorage.getItem('cookieconsent')) {
    document.querySelector('.cookieconsent').style.display = 'block';
    document.querySelector('.cookieconsent a').onclick = function(e) {
      e.preventDefault();
      document.querySelector('.cookieconsent').style.display = 'none';
      localStorage.setItem('cookieconsent', true);
    };
  }
})();


var d3 = require("d3");

 var w = 160;
 var h = 160;
 var contentIndex = 0;


 var svg = d3.select("#svgContainer")
              .append("div")
              .classed("svg-container", true) //container class to make it responsive
              .append("svg")
              //responsive SVG needs these 2 attributes and no width and height attr
              .attr("preserveAspectRatio", "xMinYMin meet")
              .attr("viewBox", "0 0 160 160")
              //class to make it responsive
              .classed("svg-content-responsive", true); 





          /*
          dataArray1  - About Me
          dataArray2  - Design
          dataArray3  - Vlog
          dataArray4  - Music
          dataArray5  - Podcast
          */

  
          



          var polySame= [
          "200,40 240,80 240,0"];  

          var dataArrays = [

          ["0,40 40,80 40,40",
          "0,80 40,120 40,80",
          "0,120 40,160 40,120",
          "40,40 0,0 0,40",
          "40,80 0,40 0,80",
          "40,80 80,120 80,80",
          "40,120 0,80 0,120",
          "40,120 80,160 80,120",
          "40,160 0,120 0,160",
          "80,80 40,40 40,80",
          "80,80 120,40 120,80",
          "80,120 40,80 40,120",
          "80,160 40,120 40,160",        
          "120,80 160,120 120,120",
          "120,120 80,80 120,80",
          "120,40 160,0 160,40",
          "120,40 160,80 120,80",
          "160,80 120,40 160,40",
          "160,120 120,80 160,80",
          "160,160 120,120 160,120"],


          ["0,40 40,80 0,80",        
          "0,40 40,80 40,40",
          "0,160 40,120 40,160",
          "40,40 0,0 0,40",
          "40,40 80,80 40,80",
          "40,40 80,80 80,40",
          "40,120 0,160 0,120",
          "80,80 120,120 120,80",
          "80,80 40,120 80,120",
          "80,120 40,160 40,120",
          "80,80 120,40 80,40",
          "80,80 40,120 40,80",
          "120,40 80,80 120,80",
          "120,40 160,0 160,40",
          "120,80 160,40 160,80",
          "120,120 80,80 80,120",
          "120,160 80,120 120,120",
          "160,40 120,80 120,40",
          "160,160 120,120 160,120",
          "160,160 120,120 120,160"],    


          ["0,0 40,40 0,40",
          "0,40 40,80 40,40",
          "0,80 40,120 40,80",
          "0,120 40,160 0,160",
          "0,120 40,160 40,120",
          "40,40 0,0 40,0",
          "40,80 0,40 0,80",
          "40,120 0,80 0,120",
          "80,40 40,0 40,40",
          "80,40 40,80 40,40",
          "120,0 160,40 120,40",
          "120,40 80,0 120,0",
          "120,40 80,80 120,80",
          "120,80 160,120 160,80",
          "120,80 160,120 120,120",
          "120,120 160,160 120,160",
          "160,40 120,0 160,0",
          "160,80 120,40 120,80",
          "160,80 120,40 160,40",
          "160,160 120,120 160,120"],
          
          ["0,40 40,0 40,40",
          "0,40 40,80 40,40",
          "0,80 40,120 40,80",
          "0,120 40,160 40,120",
          "40,0 80,40 40,40",
          "40,80 80,120 80,80",
          "40,120 0,80 0,120",
          "40,120 80,160 80,120",
          "40,160 0,120 0,160",
          "80,0 120,40 80,40",
          "80,40 40,0 80,0",
          "80,120 40,80 40,120",
          "80,120 120,160 120,120",
          "80,160 40,120 40,160",
          "120,40 80,0 120,0",
          "120,120 80,80 80,120",
          "120,120 80,80 120,80",
          "120,120 160,80 160,120",
          "120,160 80,120 80,160",
          "160,160 120,120 160,120"],

          
          ["0,120 40,160 40,120",
          "40,0 80,40 40,40",
          "40,40 80,80 80,40",
          "40,80 80,120 80,80",
          "40,160 0,120 0,160",
          "40,160 80,120 40,120",
          "80,0 120,40 80,40",
          "80,40 40,0 80,0",
          "80,80 40,40 40,80",
          "80,120 40,80 40,120",
          "80,120 120,160 120,120",
          "120,0 160,40 120,40",
          "120,40 80,0 120,0",
          "120,80 160,120 120,120",
          "120,80 160,120 160,80",
          "120,160 80,120 80,160",
          "160,40 120,0 160,0",
          "160,80 120,40 120,80",
          "160,80 120,40 160,40",
          "160,120 120,160 120,120"]];

          var polyBase = [
          "0,0 0,160 80,160 80,80",
          "200,40 240,80 240,0",
          "80,80 160,160 160,0"];

          //globals


          var basePoly=svg.append("g").attr("id", "basePolyGroup").selectAll("polygon")
          .data(polyBase)
          .enter()
          .append("polygon")
          .attr("points",function(d,i){return d})
          .attr("fill", "#a3a3a3")
          .attr("stroke", "#a3a3a3")
          .attr("stroke-width", 0.75);

          //create basic circles
          var transformPoly=svg.append("g").attr("id", "transformPolyGroup").selectAll("polygon")
          .data(dataArrays[contentIndex])
          .enter()
          .append("polygon")
          .attr("points",function(d,i){return d})
          .attr("fill", "black")
          .attr("opacity", 1)
          .attr("stroke", "black")
          .attr("stroke-width", 0.75);




          var samePoly=svg.append("g").attr("id", "samePolyGroup").selectAll("polygon")
          .data(polySame)
          .enter()
          .append("polygon")
          .attr("points",function(d,i){return d})
          .attr("fill", "black")
          .attr("opacity", 1)
          .attr("stroke", "black")
          .attr("stroke-width", 0.75);




          function updatepoly(dataSet, contentIndex){
              //select new data

              var dataSet;


              //rejoin data
              var polyCon = transformPoly
              .data(dataSet);

              polyCon.exit().remove();//remove unneeded circles
              
              polyCon.enter().append("polygon")
                  .attr("points",0);//create any new circles needed

              //update all circles to new positions

              samePoly.transition()
              .duration(100)
              .attr("points",function(d){return d})
              .attr("transform","translate(24,0)")
              .transition()
              .duration(500)
              .attr("transform","translate(0,0)")
              .on("start", function  repeat() {

                polyCon.transition()
                .duration(500)
                .attr("points",function(d,i){return d})
                .attr("fill", polyColor[contentIndex])
                .attr("stroke", polyColor[contentIndex]);

                basePoly.transition()
                 .duration(500)
                 .attr("fill", baseColor[contentIndex])
                 .attr("stroke", baseColor[contentIndex]);


            })

              .duration(500)
              .attr("fill", polyColor[contentIndex])
              .attr("stroke", polyColor[contentIndex]);
           
         




              d3.select("text").text(dataSet);

              return dataSet;
              return contentIndex;
          }












function  contentSwitch(){
    



      indicatorUpdate();
      animDescCon();
      changeText();
      updatepoly(dataArrays[contentIndex],contentIndex);


    $(".menuindicator").velocity({borderColor: wordColor[contentIndex]},{ duration: 500 });





    menu.find("li").removeClass("activea");
        $(navButtons[contentIndex]).addClass("activea");
        position_menuindicator(menu.find("li.activea"));


 }









 $("#aboutme, #aboutme-offCanvas,#aboutme-indicator").click(function(){
  if (contentIndex!=0){

    oldContentIndex=contentIndex;
    contentIndex=0;
    contentSwitch(); 
}
});

 $("#design, #design-offCanvas,#design-indicator").click(function(){
  if ( contentIndex!=1){

     oldContentIndex=contentIndex;
    contentIndex=1;
    contentSwitch();
  }
});

 $("#podcast, #podcast-offCanvas,#podcast-indicator").click(function(){
  if (contentIndex!=2){

     oldContentIndex=contentIndex;
    contentIndex=2;
    contentSwitch();
  }
});


 $("#vlog, #vlog-offCanvas,#vlog-indicator").click(function(){
    
  if ( contentIndex!=3){

    oldContentIndex=contentIndex;
    contentIndex=3;
    contentSwitch();
  }
});

 $("#music, #music-offCanvas, #music-indicator").click(function(){
  if (contentIndex!=4){

    oldContentIndex=contentIndex;
    contentIndex=4;
    contentSwitch();
  }
});






});
















